<template>
    <footer class="footer">
        <div class="footer-content d-flex align-items-center justify-content-between rounded-pill">
            <div class="footer-menus d-flex align-items-center">
                <router-link class="nav-link fs16 lh-125" to="/about-us">About Us</router-link>
                <router-link class="nav-link fs16 lh-125" to="/our-brands">Our Brands</router-link>
                <router-link class="nav-link fs16 lh-125" to="/management">Management</router-link>
                <router-link class="nav-link fs16 lh-125" to="/news"> News</router-link>
                <router-link class="nav-link fs16 lh-125" to="/contact-us">Contact Us</router-link>
            </div>
            <div class="social-links d-flex align-items-center">
                <a class="me-3" href="https://www.facebook.com/finvasia/" target="_blank">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.9997 2.66699H19.9997C18.2316 2.66699 16.5359 3.36937 15.2856 4.61961C14.0354 5.86986 13.333 7.56555 13.333 9.33366V13.3337H9.33301V18.667H13.333V29.3337H18.6663V18.667H22.6663L23.9997 13.3337H18.6663V9.33366C18.6663 8.98004 18.8068 8.6409 19.0569 8.39085C19.3069 8.1408 19.6461 8.00033 19.9997 8.00033H23.9997V2.66699Z" stroke="#C66755" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a class="mx-3" href="https://www.instagram.com/finvasia/?hl=en" target="_blank">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.667 2.66699H9.33366C5.65176 2.66699 2.66699 5.65176 2.66699 9.33366V22.667C2.66699 26.3489 5.65176 29.3337 9.33366 29.3337H22.667C26.3489 29.3337 29.3337 26.3489 29.3337 22.667V9.33366C29.3337 5.65176 26.3489 2.66699 22.667 2.66699Z" stroke="#C66755" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21.3336 15.1604C21.4981 16.2701 21.3086 17.4034 20.7919 18.3991C20.2753 19.3948 19.4578 20.2023 18.4558 20.7066C17.4537 21.211 16.3182 21.3865 15.2106 21.2083C14.1031 21.0301 13.0799 20.5072 12.2867 19.714C11.4935 18.9207 10.9706 17.8976 10.7924 16.79C10.6141 15.6825 10.7897 14.5469 11.294 13.5449C11.7984 12.5429 12.6058 11.7254 13.6016 11.2087C14.5973 10.6921 15.7306 10.5025 16.8403 10.6671C17.9722 10.8349 19.0201 11.3624 19.8292 12.1715C20.6383 12.9806 21.1657 14.0285 21.3336 15.1604Z" stroke="#C66755" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M23.333 8.66699H23.3463" stroke="#C66755" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a class="mx-3" href="https://in.linkedin.com/company/finvasia" target="_blank">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.333 10.667C23.4547 10.667 25.4896 11.5098 26.9899 13.0101C28.4902 14.5104 29.333 16.5453 29.333 18.667V28.0003H23.9997V18.667C23.9997 17.9597 23.7187 17.2815 23.2186 16.7814C22.7185 16.2813 22.0403 16.0003 21.333 16.0003C20.6258 16.0003 19.9475 16.2813 19.4474 16.7814C18.9473 17.2815 18.6663 17.9597 18.6663 18.667V28.0003H13.333V18.667C13.333 16.5453 14.1759 14.5104 15.6762 13.0101C17.1764 11.5098 19.2113 10.667 21.333 10.667V10.667Z" stroke="#C66755" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.00033 12H2.66699V28H8.00033V12Z" stroke="#C66755" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.33366 8.00033C6.80642 8.00033 8.00033 6.80642 8.00033 5.33366C8.00033 3.8609 6.80642 2.66699 5.33366 2.66699C3.8609 2.66699 2.66699 3.8609 2.66699 5.33366C2.66699 6.80642 3.8609 8.00033 5.33366 8.00033Z" stroke="#C66755" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a class="ms-3" href="https://twitter.com/finvasia" target="_blank">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.6663 3.99974C29.3895 4.90037 27.9758 5.58922 26.4797 6.03974C25.6767 5.11642 24.6095 4.46199 23.4224 4.16497C22.2353 3.86795 20.9857 3.94266 19.8424 4.37901C18.6992 4.81535 17.7176 5.59227 17.0303 6.60469C16.343 7.61711 15.9833 8.81618 15.9997 10.0397V11.3731C13.6565 11.4338 11.3347 10.9142 9.24102 9.86033C7.14733 8.80651 5.34676 7.25125 3.99967 5.33307C3.99967 5.33307 -1.33366 17.3331 10.6663 22.6664C7.92038 24.5304 4.64922 25.465 1.33301 25.3331C13.333 31.9997 27.9997 25.3331 27.9997 9.99974C27.9984 9.62834 27.9627 9.25786 27.893 8.89307C29.2538 7.55106 30.2141 5.85669 30.6663 3.99974V3.99974Z" stroke="#C66755" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
            <div class="privacy-links d-flex align-items-center">
                 <a class="nav-link fs16 lh-125 pointer-none text-white" href="javascript:void(0)">Terms of Use</a>
                <a class="nav-link fs16 lh-125 pointer-none text-white" href="javascript:void(0)">Privacy Policy</a>
                <!-- <router-link class="nav-link fs16 lh-125" to="/terms-and-conditions">Terms of Use</router-link>
                <router-link class="nav-link fs16 lh-125" to="/privacy-policy">Privacy Policy</router-link> -->
                <p class="mb-0 ms-5 fw-medium me-xl-3 pe-1">Copyright 2023. finvasia.com</p>
            </div>
        </div>
    </footer>    
</template>
<script>
import commonAllmixins from "@/plugins/commonAll";
//import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    mixins:[commonAllmixins],
    data() {
        return {
            form : {
                email : ""
            },
            pageLoader : false,
            errorMsg : '',
            msgType : ''
        }
    },  
    // components: { 
    //     Form,
    //     Field,
    //     ErrorMessage,
    // },   
    methods:{
        submit(){
            const _that = this
            let input_json={
                'globalParams' :{                       
                    "nl_email": _that.form.email,                 
                },
                'localParams' :{ "parameter" : _that }
            }
            _that._runsubscribeNewsletter(input_json)
        }
    } 
}
</script>
<style>
    .loader {
      border: 16px solid #f3f3f3; /* Light grey */
      border-top: 16px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
    }

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
