export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL
export const CONTACT_BASE_URL = process.env.VUE_APP_API_BASE_CONTACT_URL

//===========================================store function api=========================================

export const EP_CONTACT_US = `${GLOBAL_BASE_URL}/api/finvgrp/contact/us`

export const EP_SUBSCRIBE_NEWSLETTER = `${GLOBAL_BASE_URL}/api/finvgrp/subscribe/newletters`

export const EP_CONTACT_US_ENQUIRY = `${CONTACT_BASE_URL}/contact/us`

export const EP_GET_NEWS_LIST = `https://blog.finvasia.com/wp-admin/posts?categories={{category}}`













