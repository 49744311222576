<template>
    <div class="topHeader" id="myHeader">
        <nav class="navbar navbar-expand-lg navbar-light w-100 p-0">
            <div class="headerLeftSide">
            <div class="logowrap">
               <router-link class="mainLogo" to="/"><img class="img-fluid" width="200" height="32" src="assets/images/logo.svg" alt="finvasia" title="finvasia"> </router-link>
            </div>
            <div class="main-navbar d-flex align-items-center justify-content-between flex-fill">
                <div class="collapse navbar-collapse me-4" id="navbarTogglerDemo02">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <!-- <li class="nav-item nav-active">
                            <router-link class="nav-link" to="/">Home</router-link>
                        </li> -->
                        <li class="nav-item nav-active">
                            <router-link class="nav-link" to="/about-us">About Us</router-link>
                        </li>
                        <li class="nav-item nav-active">
                            <router-link class="nav-link" to="/our-brands">Brands</router-link>
                        </li> 
                        <!-- <li class="nav-item nav-active">
                            <router-link class="nav-link" to="/">Our Approach</router-link>
                        </li> -->
                        <li class="nav-item nav-active">
                            <router-link class="nav-link" to="/management">Management</router-link>
                        </li>
                        <!-- <li class="nav-item nav-active">
                            <router-link class="nav-link" to="/awards">Our Awards </router-link>
                        </li> -->
                        <li class="nav-item nav-active">
                            <router-link class="nav-link" to="/news"> News</router-link>
                        </li>
                        <li class="nav-item nav-active hideContactUpDesktop">
                            <router-link class="nav-link" to="/contact-us"> Contact Us</router-link>
                        </li>
                    </ul>
                </div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" @click.stop="toggleButton()">
                    <span class=""></span>
                    <span class=""></span>
                    <span class=""></span>
                </button>
            </div>
                <div class="navbar-light rightLogin">
                    <router-link class="themeBtn fs18 rounded-pill h54" to="/contact-us">Contact Us </router-link>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    import $ from 'jquery'
export default {
    data(){
        return {

        }
    },
    methods:{
        toggleButton(){
            if($('.navbar-toggler').hasClass('collapsed')){
                $('.navbar-toggler').removeClass('collapsed')
                $('.navbar-collapse').removeClass('show')
                 $('body').removeClass('bodyFixed')
            }else{
                $('.navbar-toggler').addClass('collapsed')
                $('.navbar-collapse').addClass('show')
                 $('body').addClass('bodyFixed')
            }
        }
    },
    created(){
        this.active = localStorage.setItem("nav-item",this.$route.name);
        this.active = localStorage.getItem("nav-item")
    },
     mounted () {
        var header = document.getElementById("myHeader");
       
        window.addEventListener('scroll',()=>{
            if ($(window).scrollTop() >= 140) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        });
        $(document).on("click", function (event) {
          if (!$(event.target).closest(".nav-link.is-menu").length) {
            $('.navbar-toggler').removeClass('collapsed')
            $('.navbar-collapse').removeClass('show')
            $('body').removeClass('bodyFixed')
          }
        });
    },
}
</script>
